import { TVI_MODE } from '@/constant/RETURN_TIME'
import { formatLocaleDate } from '@/libs/utils/format-locale-date.util'
import { PeriodWithIdType } from '@/services/coverage/useCoveragesPeriod/types'
import dayjs from 'dayjs'

const TimelineChartHook = ({ data }: { data?: PeriodWithIdType[] }) => {
  const formatLocale = (date: Date) => formatLocaleDate(date, 'th', 'D MMM BBBB, HH:mm')

  const formatLabelTime = (date: dayjs.ConfigType) => dayjs(date).format('HH:mm')

  const getTripSummary = (
    data?: PeriodWithIdType[],
  ): {
    minStartTime: string
    maxEndTime: string
    modeStartDateTime: string
    modeEndDateTime: string
    totalDuration: string
  } => {
    if (!data || data.length === 0) {
      return {
        minStartTime: '-',
        maxEndTime: '-',
        modeStartDateTime: '-',
        modeEndDateTime: '-',
        totalDuration: '-',
      }
    }

    const result = data.reduce(
      (acc, period) => {
        const { startDateTime, endDateTime, tviMode, duration } = period
        const currentStartTime = new Date(startDateTime).getTime()
        const currentEndTime = new Date(endDateTime).getTime()

        if (currentStartTime < acc.minStartTime) {
          acc.minStartTime = currentStartTime
          acc.minStartTimeStatus = tviMode
        }

        if (currentEndTime > acc.maxEndTime) {
          acc.maxEndTime = currentEndTime
          acc.maxEndTimeStatus = tviMode
        }

        acc.totalDuration += duration
        return acc
      },
      {
        minStartTime: new Date(data[0].startDateTime).getTime(),
        maxEndTime: new Date(data[0].endDateTime).getTime(),
        minStartTimeStatus: data[0].tviMode,
        maxEndTimeStatus: data[0].tviMode,
        totalDuration: 0,
      },
    )

    return {
      minStartTime: formatLocale(new Date(result.minStartTime)),
      maxEndTime: formatLocale(new Date(result.maxEndTime)),
      modeStartDateTime: TVI_MODE[result.minStartTimeStatus],
      modeEndDateTime: TVI_MODE[result.maxEndTimeStatus],
      totalDuration: `${result.totalDuration} นาที`,
    }
  }

  const calculateTripWidth = (duration: number, totalDuration: number, width: number) =>
    (duration / totalDuration) * width

  const formatEventDetails = (start: Date, end: Date) => ({
    startDate: formatLocale(start),
    endDate: formatLocale(end),
    period: dayjs(end).diff(start, 'm'),
  })

  const roundToNearestFive = (timeInMs: number) => {
    const date = dayjs(timeInMs)
    const minutes = date.minute()
    const roundedMinutes = Math.round(minutes / 5) * 5
    return date.minute(roundedMinutes).second(0).millisecond(0)
  }

  const { minStartTime, maxEndTime, modeStartDateTime, modeEndDateTime, totalDuration } =
    getTripSummary(data)

  return {
    minStartTime,
    maxEndTime,
    modeStartDateTime,
    modeEndDateTime,
    totalDuration,
    calculateTripWidth,
    formatEventDetails,
    formatLabelTime,
    roundToNearestFive,
  }
}

export default TimelineChartHook
