import Table from '@/components/common/Table'
import ReturnTimeTableHook from './ReturnTimeTable.hook'
import { SearchInput, Container, SearchIcon } from './styles'
import ReturnTimeReasonModal from '@/components/feature/ReturnTimePage/ReturnTimeReasonModal/ReturnTimeReasonModal'

function ReturnTimeTable({ policyNumber }: { policyNumber?: string | null }) {
  const { columns, returnTime, onTableChange, onSearch, selectPpuMotorReturnTimeId, closeModal } =
    ReturnTimeTableHook({ policyNumber })

  return (
    <Container>
      {!policyNumber ? (
        <SearchInput
          data-testid="return-time-search-input"
          placeholder="ค้นหาชื่อผู้เอาประกัน, เลขที่กรมธรรม์"
          prefix={<SearchIcon />}
          onChange={onSearch}
        />
      ) : undefined}
      <Table
        rowKey="id"
        data-testid="return-time-table"
        columns={columns.filter((column) => column.width !== 0)}
        loading={returnTime.isFetching}
        dataSource={returnTime?.data?.data}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100],
          showSizeChanger: true,
          total: returnTime.data?.meta.totalItems,
          current: returnTime.data?.meta.currentPage,
          defaultCurrent: 1,
          defaultPageSize: 10,
          pageSize: returnTime.data?.meta.itemsPerPage,
        }}
        onChange={(pagination, _filter, sorter) => onTableChange(pagination, sorter)}
      />
      {selectPpuMotorReturnTimeId && (
        <ReturnTimeReasonModal
          open={!!selectPpuMotorReturnTimeId}
          ppuMotorReturnTimeId={selectPpuMotorReturnTimeId}
          closeModal={closeModal}
        />
      )}
    </Container>
  )
}

export default ReturnTimeTable
