export enum ReturnTimeTopicEnum {
  NORMAL = 'normal',
  TRAFFIC_NO_PREMIUM = 'traffic_no_premium',
}

export const TVI_MODE = {
  TVICONNECT: 'TVI connect',
  MANUAL: 'Manual',
  SCHEDULE: 'Schedule',
}

export const TVI_STATUS_COLORS = {
  DRIVING: '#20B857',
  IDLE: '#FF8F00',
  STANDBY: '#0290FF',
  TRAFFIC: '#FF3511',
  NO_SIGNAL: '#666666',
}

export enum TviStatusEnum {
  DRIVING = 'DRIVING',
  IDLE = 'IDLE',
  STANDBY = 'STANDBY',
  TRAFFIC = 'TRAFFIC',
  NO_SIGNAL = 'NO_SIGNAL',
}

export const HISTORY_CAR_USAGE_PAGINATION_LIMIT = 15
