import { ORDER_TAB, OrderTabEnum } from '@/constant/TA_TRANSACTION'
import { Breadcrumb, SelectItem } from './styles'
import TaTransactionOrderHeaderHook from './TaTransactionOrderHeader.hook'

const TaTransactionOrderHeader = ({
  orderTab,
  orderNumber,
}: {
  orderTab: OrderTabEnum
  orderNumber: string | undefined
}) => {
  const { goBack } = TaTransactionOrderHeaderHook()
  return (
    <Breadcrumb
      items={[
        { title: 'รายการสั่งซื้อประกันภัยการเดินทาง' },
        { title: <SelectItem onClick={goBack}>{ORDER_TAB[orderTab]}</SelectItem> },
        { title: orderNumber },
      ]}
    />
  )
}

export default TaTransactionOrderHeader
