import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeCreateActiveHealthPartnerBranchDataInterface,
  BackofficeCreateActiveHealthPartnerBranchInputInterface,
} from './interface'

export const backofficeCreateActiveHealthPartnerBranch: TypedDocumentNode<
  BackofficeCreateActiveHealthPartnerBranchDataInterface,
  BackofficeCreateActiveHealthPartnerBranchInputInterface
> = gql`
  mutation BackofficeCreateActiveHealthPartnerBranch(
    $input: CreateActiveHealthPartnerBranchInput!
  ) {
    backofficeCreateActiveHealthPartnerBranch(input: $input) {
      id
      displayNameTh
      displayNameEn
      qr {
        id
        isActive
        imageUrl
      }
      activeHealthPartnerBranchContacts {
        id
        phoneNumber
        extensionNumber
      }
    }
  }
`

export default backofficeCreateActiveHealthPartnerBranch
