import styled from '@emotion/styled'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Skeleton as AntdSkeleton } from 'antd'

export const Container = styled('div')`
  width: 100%;
  padding: 40px;
`

export const SubContainer = styled('div')`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  margin-top: 16px;
`

export const Title = styled('div')`
  font-size: 21px;
  color: #000000;
`

export const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`

export const DateTimeText = styled('div')`
  font-size: 12px;
  color: #000000;
`

export const BackIcon = styled(ArrowLeftOutlined)`
  font-size: 16px;
  color: #000000;
  cursor: pointer;
  margin-right: 8px;
`

export const Skeleton = styled(AntdSkeleton)`
  margin: 4px 0;
`
