import { TVI_STATUS_COLORS, TviStatusEnum } from '@/constant/RETURN_TIME'
import { Geometry, Feature } from 'geojson'

function HistoryCarUsageMapHook() {
  const getColorByTviStatus = (tviStatus: string): string => {
    switch (tviStatus) {
      case TviStatusEnum.IDLE:
        return TVI_STATUS_COLORS.IDLE
      case TviStatusEnum.DRIVING:
        return TVI_STATUS_COLORS.DRIVING
      case TviStatusEnum.TRAFFIC:
        return TVI_STATUS_COLORS.TRAFFIC
      case TviStatusEnum.STANDBY:
        return TVI_STATUS_COLORS.STANDBY
      case TviStatusEnum.NO_SIGNAL:
        return TVI_STATUS_COLORS.NO_SIGNAL
      default:
        return '#0000FF'
    }
  }

  const style = (feature: Feature<Geometry, any>) => {
    return {
      color: getColorByTviStatus(feature.properties.tviStatus),
      weight: 7,
      opacity: 1,
    }
  }

  return {
    style,
  }
}

export default HistoryCarUsageMapHook
