import { TVI_STATUS_COLORS, TviStatusEnum } from '@/constant/RETURN_TIME'
import {
  ColAntd,
  Container,
  ModeBody,
  ModeCard,
  ModeMinute,
  ModeStatus,
  ModeText,
  RowAntd,
} from './styles'
import ModeSummarizeHook from './ModeSummarize.hook'
import { PeriodWithIdType } from '@/services/coverage/useCoveragesPeriod/types'

const ModeSummarize = ({
  tripStatus,
  data,
  handleOnClick,
}: {
  tripStatus?: TviStatusEnum
  data?: PeriodWithIdType[]
  handleOnClick?: (data: any) => void
}) => {
  const { calculateMinutesAndPercentage } = ModeSummarizeHook()
  const result = calculateMinutesAndPercentage(data)

  return (
    <Container>
      <RowAntd gutter={[8, 8]}>
        {Object.entries(TVI_STATUS_COLORS).map(([status, color]) => {
          return (
            <ColAntd flex={2.4} key={status}>
              <ModeCard
                isAction={tripStatus === status}
                onClick={() => {
                  if (!handleOnClick) return
                  handleOnClick(tripStatus === status ? undefined : status)
                }}
              >
                <ModeStatus color={color} text={status} />
                <ModeBody>
                  <ModeText>
                    <ModeMinute>{result[status]?.minutes || '0'}</ModeMinute>นาที
                  </ModeText>
                  <ModeText>
                    {result[status]?.percentage || '0'}
                    {' %'}
                  </ModeText>
                </ModeBody>
              </ModeCard>
            </ColAntd>
          )
        })}
      </RowAntd>
    </Container>
  )
}

export default ModeSummarize
