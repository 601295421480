import Table from '@/components/common/Table'
import HistoryCarUsageTableHook from './HistoryCarUsageTable.hook'
import { CoveragesPaginationResponse } from '@/services/returnTime/useCoveragesPagination/types'

const HistoryCarUsageTable = ({
  isLoading,
  currentPage,
  currentPageSize,
  pagination,
  handleOnChangeTable,
}: {
  isLoading?: boolean
  currentPage?: number
  currentPageSize?: number
  pagination?: CoveragesPaginationResponse
  handleOnChangeTable?: (data: any) => void
}) => {
  const { columns } = HistoryCarUsageTableHook({ currentPage, currentPageSize })

  return (
    <Table
      data-testid="history-car-usage-table"
      rowKey="id"
      columns={columns.filter((column) => column.width !== 0)}
      loading={isLoading}
      dataSource={pagination?.data}
      pagination={{
        pageSizeOptions: [10, 25, 50, 100],
        showSizeChanger: true,
        total: pagination?.meta?.totalItems,
        defaultCurrent: 1,
        current: currentPage,
        defaultPageSize: 10,
        pageSize: currentPageSize,
      }}
      onChange={handleOnChangeTable}
    />
  )
}

export default HistoryCarUsageTable
