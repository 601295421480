import Table from '@/components/common/Table'
import { Container, SearchInput, Title, SearchIcon } from './styles'
import WellBeingPartnerTableHook from './WellBeingPartnerTable.hook'

function WellBeingPartnerTable() {
  const {
    columns,
    activeHealthPartnerData,
    activeHealthPartnerLoading,
    onTablePaginate,
    onSearchInputChange,
    pagination,
    onTableChange,
  } = WellBeingPartnerTableHook()

  return (
    <Container>
      <Title>รายชื่อพาร์ทเนอร์</Title>
      <SearchInput
        id="well-being-search"
        data-testid="well-being-search-input"
        prefix={<SearchIcon />}
        placeholder="ค้นหาพาร์ทเนอร์"
        onChange={() => onSearchInputChange(true)}
      />
      <Table
        rowKey="id"
        data-testid="well-being-partner-table"
        loading={activeHealthPartnerLoading}
        columns={columns}
        dataSource={activeHealthPartnerData?.backofficeGetActiveHealthPartnerPagination.data}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100],
          showSizeChanger: true,
          total: pagination.totalItems,
          current: pagination.page,
          defaultCurrent: 1,
          pageSize: pagination.limit,
          onChange: (page, pageSize) => onTablePaginate(page, pageSize),
        }}
        onChange={(_pagination, _filter, sorter) => onTableChange(sorter)}
      />
    </Container>
  )
}

export default WellBeingPartnerTable
