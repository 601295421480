import BuddhistDatePicker from '@/components/common/BuddhistDatePicker/BuddhistDatePicker'
import {
  Button as AntdButton,
  Col,
  Row,
  Form as AntdForm,
  Input as FormInput,
  Radio as AntdRadio,
  Select as AntdSelect,
} from 'antd'
import styled from '@emotion/styled'

export const Container = styled('div')``

export const RowAntd = styled(Row)``

export const Button = styled(AntdButton)``

export const ColAntd = styled(Col)``

export const FormList = styled(AntdForm.List)``

export const Form = styled(AntdForm)``

export const FormItem = styled(AntdForm.Item)`
  margin-bottom: 16px;

  label {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  .ant-form-item-label {
    margin: 0;
    padding: 0 0 4px;
    white-space: initial;
    text-align: start;
  }
`

export const LabelOptional = styled('div')`
  color: #adadad;
  font-weight: 400;
  padding-left: 5px;
`

export const Input = styled(FormInput)`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
  border-radius: 8px;
`

export const Select = styled(AntdSelect)`
  .ant-select-selector {
    height: 40px !important;
    padding: 5px 16px !important;
    border-radius: 8px !important;
  }

  .ant-select-selection-item {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    padding: 5px 0px !important;
  }

  .ant-select-selection-search {
    padding: 5px 0px !important;
  }
`

export const BoxTitle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 6px;
  padding-bottom: 14px;
`

export const Title = styled('div')`
  display: flex;
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`

export const Mark = styled('div')`
  padding-left: 5px;
  color: #adadad;
  font-weight: 400;
`

export const AddContact = styled('div')`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #009640;
  cursor: pointer;
`

export const BoxContact = styled('div')`
  padding: 25px;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
`

export const BoxNoContact = styled('div')`
  text-align: center;
  padding-top: 20px;
`

export const TitleNoContact = styled('div')`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`

export const SubTitleNoContact = styled('div')`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
`

export const DeleteIcon = styled('img')`
  position: absolute;
  right: 0;
  margin: 15px;
  height: 20px;
  width: 20px;
  cursor: pointer;
`

export const Line = styled('div')`
  background: #e5e5e5;
  margin: 25px 0px;
  height: 1px;
`

export const BuddhistDatePickerStyled = styled(BuddhistDatePicker.RangePicker)`
  width: 50%;
`

export const RadioGroup = styled(AntdRadio.Group)`
  width: 100%;

  .ant-radio {
    align-self: start;
    padding-right: 5px;
  }

  .ant-radio-wrapper-checked {
    border: 1px solid #009640 !important;
  }

  .ant-radio-wrapper {
  }
`

export const Radio = styled(AntdRadio)`
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  width: 100%;
`

export const RadioTitle = styled('div')`
  display: flex;
  font-weight: 500;
  color: #000000;
`

export const RadioSubTitle = styled('div')`
  color: #666666;
  font-weight: 400;
`

export const MarkOpen = styled('div')`
  color: #009640;
  padding-right: 5px;
`

export const MarkClose = styled('div')`
  color: #ef2605;
  padding-right: 5px;
`
