import {
  Container,
  Title,
  SearchInput,
  SearchIcon,
  ContainerAction,
  ButtonAction,
  ButtonIcon,
} from './styles'
import Table from '@/components/common/Table'
import WellBeingBranchTableHook from './WellBeingBranchTable.hook'
import { IMAGE_URL } from '@/constant/IMAGE'
import WellBeingBranchModal from '../WellBeingBranchModal'
import { useParams } from 'react-router-dom'

const WellBeingBranchTable = () => {
  const { partnerId } = useParams<{ partnerId: string }>()

  const {
    columns,
    activeHealthPartnerBranchData,
    activeHealthPartnerBranchLoading,
    pagination,
    isAddBranch,
    isModalOpen,
    onTablePaginate,
    setIsTyping,
    onTableChange,
    handleAddPartner,
    handleModalOk,
    handleModalCancel,
  } = WellBeingBranchTableHook({ partnerId })

  const dataSource = activeHealthPartnerBranchData
    ?.backofficeGetActiveHealthPartnerBranchPaginationWithScanCount.data
    ? activeHealthPartnerBranchData?.backofficeGetActiveHealthPartnerBranchPaginationWithScanCount.data.map(
        (data, index) => {
          return { index, ...data }
        },
      )
    : []

  return (
    <Container>
      <Title>จำนวน {dataSource.length || 0} สาขา</Title>
      <ContainerAction>
        <SearchInput
          id="well-being-branch-search"
          data-testid="well-being-branch-search-input"
          prefix={<SearchIcon />}
          placeholder="ค้นหาชื่อสาขา..."
          onChange={() => setIsTyping(true)}
        />
        {isAddBranch && (
          <ButtonAction
            id="well-being-branch-add"
            type="primary"
            icon={<ButtonIcon src={IMAGE_URL.plusIcon} />}
            onClick={handleAddPartner}
          >
            เพิ่มสาขา
          </ButtonAction>
        )}
      </ContainerAction>
      <Table
        rowKey="index"
        data-testid="well-being-branch-table"
        columns={columns}
        dataSource={dataSource}
        loading={activeHealthPartnerBranchLoading}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100],
          showSizeChanger: true,
          total: pagination.totalItems,
          current: pagination.page,
          defaultCurrent: 1,
          pageSize: pagination.limit,
          onChange: (page, pageSize) => onTablePaginate(page, pageSize),
        }}
        onChange={(_pagination, _filters, sorter) => onTableChange(sorter)}
      />
      {partnerId && (
        <WellBeingBranchModal
          partnerId={partnerId}
          isModalOpen={isModalOpen}
          handleOk={handleModalOk}
          handleCancel={handleModalCancel}
          title="เพิ่มสาขา"
        />
      )}
    </Container>
  )
}

export default WellBeingBranchTable
