import { PeriodWithIdType } from '@/services/coverage/useCoveragesPeriod/types'

const ModeSummarizeHook = () => {
  const getMinutesDifference = (start: Date, end: Date): number => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    return (endDate.getTime() - startDate.getTime()) / 60000 // milliseconds to minutes
  }

  const calculateMinutesAndPercentage = (data?: PeriodWithIdType[]) => {
    if (!data || data.length === 0) return {}

    const totalDurations: { [key: string]: number } = {}
    let totalMinutes = 0

    // calculate total duration for each tviStatus
    data.forEach((trip) => {
      const minutes = trip.duration
      totalMinutes += minutes

      if (totalDurations[trip.tviStatus]) {
        totalDurations[trip.tviStatus] += minutes
      } else {
        totalDurations[trip.tviStatus] = minutes
      }
    })

    // calculate percentage for each tviStatus and round it
    const result: { [key: string]: { minutes: number; percentage: number } } = {}

    Object.entries(totalDurations).forEach(([status, minutes]) => {
      result[status] = {
        minutes,
        percentage: Math.round((minutes / totalMinutes) * 100),
      }
    })

    return result
  }

  return { calculateMinutesAndPercentage }
}

export default ModeSummarizeHook
