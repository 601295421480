import { Popover } from 'antd'
import styled from '@emotion/styled'

export const Container = styled('div')`
  padding-top: 15px;
`

export const HeaderBlock = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ContainerStartTime = styled('div')`
  text-align: left;
`

export const ContainerStopTime = styled('div')`
  text-align: right;
`

export const TimeTitle = styled('div')`
  color: #adadad;
  padding-bottom: 8px;
`

export const TimeBody = styled('div')`
  display: flex;
  padding-bottom: 8px;
`
export const TimeBodyMode = styled('div')`
  padding-left: 5px;
  color: #666666;
`

export const BoxTime = styled('div')`
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 10px;
  color: #ffffff;
  border-radius: 8px;
  background-color: #474747;
`

export const LabelTime = styled('svg')`
  width: 100%;
  height: auto;
`

export const LabelTimeText = styled('text')`
  font-size: 10px;
  fill: #adadad;
`

export const Timeline = styled('svg')`
  width: 100%;
  height: auto;
  border-radius: 5px;
  background-color: #f6f6f6;
`

export const Line = styled('rect')`
  cursor: pointer;
`

export const PopoverAntd = styled(Popover)``

export const PopoverText = styled('div')``
