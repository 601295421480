import Table from '@/components/common/Table'
import HistoryCarUsagePeriodTableHook from '@/components/feature/PolicyDetailPage/HistoryCarUsagePeriodTable/HistoryCarUsagePeriodTable.hook'
import { Container } from './styles'
import { PeriodWithIdType } from '@/services/coverage/useCoveragesPeriod/types'
import { TviStatusEnum } from '@/constant/RETURN_TIME'

type HistoryCarUsagePeriodTableProps = {
  periodId?: string
  tripStatus?: TviStatusEnum
  data?: PeriodWithIdType[]
  onRowClick: (id: string) => void
}

const HistoryCarUsagePeriodTable = ({
  periodId,
  tripStatus,
  data,
  onRowClick,
}: HistoryCarUsagePeriodTableProps) => {
  const { columns } = HistoryCarUsagePeriodTableHook({
    onRowClick,
  })
  return (
    <Container>
      <Table
        data-testid="history-car-usage-period-table"
        rowKey="id"
        columns={columns}
        dataSource={data?.filter(
          (period) =>
            (!periodId || period.id === periodId) &&
            (!tripStatus || period.tviStatus === tripStatus),
        )}
      />
    </Container>
  )
}

export default HistoryCarUsagePeriodTable
