import styled from '@emotion/styled'
import { Badge } from 'antd'

export const Container = styled('div')`
  padding: 10px 0px;
`

export const ModeStatus = styled(Badge)``

export const ActionText = styled('p')`
  font-weight: 400;
  font-size: 14px;
  color: #009640;
  cursor: pointer;
`
