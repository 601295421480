import { createContext } from 'react'
import { ContextTypes } from './types'

const defaultValue: ContextTypes = {
  handleSetTabActiveKey: (key) => undefined,
  getPolicyByPolicyNumber: () => undefined,
  isComponentLoading: false,
  setSelectCoverageId: (id) => undefined,
  selectedCoverageId: '',
}

const PolicyDetailContext = createContext(defaultValue)

export default PolicyDetailContext
