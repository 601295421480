import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { httpClientPrivate } from '@/libs/httpClient'

import {
  CoveragesPaginationQuery,
  CoveragesPaginationParamsType,
  CoveragesPaginationResponse,
} from './types'
import { PaginateQuery } from '../types'
import config from '@/config'

const fetchCoveragesPagination = async (
  query?: CoveragesPaginationQuery,
  pagination?: PaginateQuery,
) => {
  const response = await httpClientPrivate.get<CoveragesPaginationResponse>(
    `${config.restUrl}/v1/backoffice-ppu-motor/coverages`,
    {
      params: {
        ...query,
        ...pagination,
      },
      paramsSerializer: {
        indexes: null,
        dots: true,
        encode: (value) => encodeURIComponent(value),
      },
    },
  )

  return response.data
}

const useCoveragesPagination = (params: CoveragesPaginationParamsType) => {
  const queryResult = useQuery({
    queryKey: ['useCoveragesPagination', params],
    queryFn: async () => fetchCoveragesPagination(params?.query, params?.pagination),
    placeholderData: keepPreviousData,
  })

  return { ...queryResult }
}

export { useCoveragesPagination, fetchCoveragesPagination }
