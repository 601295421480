import styled from '@emotion/styled'
import { Input as AntdInput } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import CommonButton from '@/components/common/Button'

export const Container = styled('div')`
  padding: 24px 32px 24px 32px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  background-color: white;
`

export const Title = styled('div')`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 16px;
`

export const ContainerAction = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`

export const SearchInput = styled(AntdInput)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  width: 320px;
`

export const SearchIcon = styled(SearchOutlined)`
  color: #adadad;
`

export const ButtonAction = styled(CommonButton)``

export const ButtonIcon = styled('img')`
  cursor: pointer;
  width: 20px;
  height: 20px;
`

export const ActionBlock = styled('div')`
  display: flex;
`

export const EnText = styled('div')`
  color: #666666;
`
