import {
  BoxTime,
  Container,
  ContainerStartTime,
  ContainerStopTime,
  HeaderBlock,
  LabelTime,
  LabelTimeText,
  Line,
  PopoverAntd,
  PopoverText,
  TimeBody,
  TimeBodyMode,
  Timeline,
  TimeTitle,
} from './styles'
import { TVI_STATUS_COLORS, TviStatusEnum } from '@/constant/RETURN_TIME'
import TimelineChartHook from './TimelineChart.hook'
import { PeriodWithIdType } from '@/services/coverage/useCoveragesPeriod/types'

const TimelineChart = ({
  periodId,
  tripStatus,
  data,
  handleOnClick,
}: {
  periodId?: string
  tripStatus?: TviStatusEnum
  data?: PeriodWithIdType[]
  handleOnClick?: (data: any) => void
}) => {
  const {
    minStartTime,
    maxEndTime,
    modeStartDateTime,
    modeEndDateTime,
    totalDuration,
    calculateTripWidth,
    formatEventDetails,
    formatLabelTime,
    roundToNearestFive,
  } = TimelineChartHook({ data })

  const renderTimeline = (width: number, height: number) => {
    if (!data || data.length === 0) return null

    let previousPositionX = 0
    const totalDuration = data.reduce((acc, { startDateTime, endDateTime }) => {
      return acc + (new Date(endDateTime).getTime() - new Date(startDateTime).getTime())
    }, 0)

    return data.map((trip, index) => {
      const { id, startDateTime, endDateTime, tviStatus } = trip
      const start = new Date(startDateTime)
      const end = new Date(endDateTime)
      const duration = end.getTime() - start.getTime()
      const tripWidth = calculateTripWidth(duration, totalDuration, width)
      const position = previousPositionX
      previousPositionX += tripWidth

      // filter by periodId
      if (periodId && periodId !== id) {
        return undefined
      }

      // filter by tviStatus
      if (tripStatus && tripStatus !== tviStatus) {
        return undefined
      }

      const { startDate, endDate, period } = formatEventDetails(start, end)
      const color = TVI_STATUS_COLORS[tviStatus]

      return (
        <PopoverAntd
          key={index}
          content={
            <>
              <PopoverText>สถานะ: {tviStatus}</PopoverText>
              <PopoverText>ระยะเวลา: {period} นาที</PopoverText>
              <PopoverText>วันเวลาเริ่มต้น: {startDate}</PopoverText>
              <PopoverText>วันเวลาสิ้นสุด: {endDate}</PopoverText>
            </>
          }
          trigger="hover"
          placement="topLeft"
        >
          <Line
            x={position}
            width={tripWidth}
            height={height}
            fill={color}
            stroke={color}
            onClick={() => {
              if (!handleOnClick) return
              handleOnClick(periodId === id ? undefined : trip)
            }}
          />
        </PopoverAntd>
      )
    })
  }

  const renderTimeLabels = (width: number, height: number) => {
    const maxLabels = 20

    if (!data || data.length === 0) return null

    // raw start and end times
    const startTime = new Date(data[0].startDateTime).getTime()
    const endTime = new Date(data[data.length - 1].endDateTime).getTime()
    const totalDuration = endTime - startTime

    // get the first and last rounded times
    const roundedStartTime = roundToNearestFive(startTime).valueOf()
    const roundedEndTime = roundToNearestFive(endTime).valueOf()
    const roundedTotalDuration = roundedEndTime - roundedStartTime

    const dynamicLabelCount = maxLabels - 2
    const interval = totalDuration / (dynamicLabelCount + 1)

    const labels = []
    let previousPositionX = 0

    labels.push(
      <LabelTimeText key="start" x={0} y={height} textAnchor="start">
        {formatLabelTime(startTime)}
      </LabelTimeText>,
    )

    for (let i = 1; i <= dynamicLabelCount; i++) {
      const labelTime = startTime + interval * i
      const roundedLabelTime = roundToNearestFive(labelTime)

      // calculate the position based on the rounded time relative to the rounded duration
      const positionX =
        ((roundedLabelTime.valueOf() - roundedStartTime) / roundedTotalDuration) * width

      if (positionX === 0 || positionX === width || previousPositionX === positionX) {
        continue
      }

      labels.push(
        <LabelTimeText key={`dynamic-${i}`} x={positionX} y={height} textAnchor="middle">
          {formatLabelTime(roundedLabelTime)}
        </LabelTimeText>,
      )

      previousPositionX = positionX
    }

    labels.push(
      <LabelTimeText key="end" x={width} y={height} textAnchor="end">
        {formatLabelTime(endTime)}
      </LabelTimeText>,
    )

    return labels
  }

  return (
    <Container>
      <HeaderBlock>
        <ContainerStartTime>
          <TimeTitle>Start</TimeTitle>
          <TimeBody>
            {minStartTime}
            <TimeBodyMode>{modeStartDateTime}</TimeBodyMode>
          </TimeBody>
        </ContainerStartTime>
        <BoxTime>{totalDuration}</BoxTime>
        <ContainerStopTime>
          <TimeTitle>Stop</TimeTitle>
          <TimeBody>
            {maxEndTime}
            <TimeBodyMode>{modeEndDateTime}</TimeBodyMode>
          </TimeBody>
        </ContainerStopTime>
      </HeaderBlock>
      <LabelTime viewBox="0 0 1000 10">{renderTimeLabels(1000, 10)}</LabelTime>
      <Timeline viewBox="0 0 1000 10">{renderTimeline(1000, 10)}</Timeline>
    </Container>
  )
}

export default TimelineChart
