import { BrowserCacheLocation, Configuration } from '@azure/msal-browser'

// Config object to be passed to Msal on creation
const msalConfig: Configuration = {
  auth: {
    clientId: process.env.AZURE_CLIENT_ID || '2f8c0ad5-df77-4816-9834-7e4a02f8ce21',
    authority:
      process.env.AZURE_AUTHORITY ||
      'https://login.microsoftonline.com/4233f6e5-fe92-4ec1-b995-bd624b92af18',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

const config = {
  // TODO: remove hard default configure, waiting for CICD updated bitbucket pipeline
  graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT || 'http://localhost:3000/graphql',
  restUrl: process.env.REACT_APP_REST_URL || 'http://localhost:3000',
  graphqlRestEndpoint: process.env.REACT_APP_GRAPHQL_REST_ENDPOINT || 'http://localhost:3000/v1',
  motorTrackerUrl:
    process.env.REACT_APP_MOTOR_TRACKER_URL ||
    'http://certificate.thaivivat.co.th/MotorTracker.php',
  appVersion: process.env.APP_VERSION,
  msalConfig,
}

export default config
