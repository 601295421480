import { ColumnsType } from 'antd/lib/table'
import { toThaiLocaleShortDate } from '@/libs/date'
import {
  ActionText,
  ModeStatus,
} from '@/components/feature/PolicyDetailPage/HistoryCarUsagePeriodTable/styles'
import { TVI_STATUS_COLORS, TviStatusEnum } from '@/constant/RETURN_TIME'
import dayjs from 'dayjs'

const HistoryCarUsagePeriodTableHook = ({ onRowClick }: { onRowClick: (id: string) => void }) => {
  const columns: ColumnsType<{}> = [
    {
      title: 'วันที่เริ่ม',
      dataIndex: 'startDateTime',
      render: (value) => {
        return toThaiLocaleShortDate(new Date(value))
      },
    },
    {
      title: 'เวลาเริ่ม',
      dataIndex: 'startDateTime',
      render: (value) => {
        return dayjs(value).format('HH:mm')
      },
    },
    {
      title: 'วันที่สิ้นสุด',
      dataIndex: 'endDateTime',
      render: (value) => {
        return toThaiLocaleShortDate(new Date(value))
      },
    },
    {
      title: 'เวลาสิ้นสุด',
      dataIndex: 'endDateTime',
      render: (value) => {
        return dayjs(value).format('HH:mm')
      },
    },
    { title: 'นาที', dataIndex: 'duration' },
    {
      title: 'สถานะ',
      dataIndex: 'tviStatus',
      render: (value) => {
        return <ModeStatus color={TVI_STATUS_COLORS[value as TviStatusEnum]} text={value} />
      },
    },
    {
      dataIndex: 'id',
      align: 'right',
      render: (id) => {
        return <ActionText onClick={() => onRowClick(id)}>ดูตำแหน่ง</ActionText>
      },
    },
  ]
  return { columns }
}

export default HistoryCarUsagePeriodTableHook
