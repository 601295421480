import {
  Block,
  Breadcrumb,
  Container,
  Information,
  InformationGroup,
  InformationTitle,
  Item,
  SubContainer,
  Title,
} from './styles'
import PolicyDetailHeaderHook from './PolicyDetailHeader.hook'
import PolicyStatusTag from '@/components/feature/PolicyDetailPage/PolicyStatusTag'
import { SearchStateEnum } from '@/components/feature/PolicyDetailPage/SearchPolicyBox/SearchPolicyBox.hook'

function PolicyDetailHeader({
  handleSetSearchState,
}: {
  handleSetSearchState: (state: SearchStateEnum) => void
}) {
  const { policy, policyDetail, handleResetAllState, handleResetPolicyStateAndDeviceDetailState } =
    PolicyDetailHeaderHook()

  const breadcrumbItems = [
    {
      title: (
        <Item
          onClick={() => {
            handleResetAllState()
            handleSetSearchState(SearchStateEnum.NONE)
          }}
        >
          ข้อมูลลูกค้าทั่วไป
        </Item>
      ),
    },
    ...(policyDetail.insuredName
      ? [
          {
            title: (
              <Item onClick={handleResetPolicyStateAndDeviceDetailState}>
                {policyDetail.insuredName}
              </Item>
            ),
          },
        ]
      : []),
    ...(policy.planName ? [{ title: policy.planName }] : []),
  ]

  return (
    <Container visible={!!policy.planName}>
      <Breadcrumb items={breadcrumbItems} />
      {policy.planName && (
        <SubContainer>
          <Block>
            <Title>{policy.planName}</Title>
            <span>
              <PolicyStatusTag policySuperAppStatus={policy.policySuperAppStatus} />
            </span>
          </Block>

          {policy.policyNumber && (
            <InformationGroup>
              <InformationTitle>เลขกรมธรรม์</InformationTitle>
              <Information>{policy.policyNumber}</Information>
            </InformationGroup>
          )}
        </SubContainer>
      )}
    </Container>
  )
}

export default PolicyDetailHeader
