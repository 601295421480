import { Layer } from 'leaflet'
import { v4 as uuidv4 } from 'uuid'
import { Geometry, Feature, GeoJsonProperties } from 'geojson'
import { PolicyDetailContext } from '@/contexts'
import { useContext, useEffect, useRef, useState } from 'react'
import { formatThaiLocaleDateWithTimeMinute } from '@/libs/date'
import { useCoveragesPeriod } from '@/services/coverage/useCoveragesPeriod'
import {
  CoveragesPeriodResponseType,
  PeriodWithIdType,
} from '@/services/coverage/useCoveragesPeriod/types'
import { TviStatusEnum } from '@/constant/RETURN_TIME'

function HistoryCarUsageDetailHook() {
  const { setSelectCoverageId, selectedCoverageId } = useContext(PolicyDetailContext)
  const [data, setData] = useState<CoveragesPeriodResponseType>()
  const [periodId, setPeriodId] = useState<string>()
  const [tviStatus, setTviStatus] = useState<TviStatusEnum>()
  const periodIdRef = useRef<string>()
  const [geoJsonData, setGeoJsonData] = useState<GeoJSON.FeatureCollection<Geometry>>()
  const coveragesPeriod = useCoveragesPeriod({ coverageId: selectedCoverageId })

  const backToPolicyDetail = () => {
    setSelectCoverageId('')
  }

  const handleOnClickTimeLine = (data: PeriodWithIdType) => {
    setPeriodId(data?.id)
    setTviStatus(undefined)
  }

  const handleOnClickModeSummarize = (status?: TviStatusEnum) => {
    setPeriodId(undefined)
    setTviStatus(status)
  }

  const handleOnViewLocation = (id: string) => {
    if (periodId === id) {
      setPeriodId(undefined)
      return
    }

    setPeriodId(id)
    setTviStatus(undefined)
  }

  const convertToGeoJson = (data: PeriodWithIdType[]): GeoJSON.FeatureCollection<Geometry> => {
    const features: Feature<Geometry, GeoJsonProperties>[] = data.map((item) => ({
      type: 'Feature',
      properties: {
        id: item.id,
        duration: item.duration,
        tviStatus: item.tviStatus,
        tviMode: item.tviMode,
        startDateTime: item.startDateTime,
        endDateTime: item.endDateTime,
      },
      geometry: {
        type: 'LineString',
        coordinates: item.events.length > 0 ? item.events.map(({ lat, lng }) => [lng, lat]) : [],
      },
    }))

    return {
      type: 'FeatureCollection',
      features,
    }
  }

  const popupLayer = (feature: Feature<Geometry, any>) => `
  <div style="font-family: 'Prompt';">
    <strong>สถานะ:</strong> ${feature.properties.tviStatus}<br/>
    <strong>ระยะเวลา:</strong> ${feature.properties.duration} นาที<br/>
    <strong>เวลาเริ่มต้น:</strong> ${formatThaiLocaleDateWithTimeMinute(
      feature.properties.startDateTime,
    )}<br/>
    <strong>เวลาสิ้นสุด:</strong> ${formatThaiLocaleDateWithTimeMinute(
      feature.properties.endDateTime,
    )}<br/>
  </div>
`

  // For map
  const onEachFeature = (feature: Feature<Geometry, any>, layer: Layer) => {
    layer.on({
      click: () => {
        if (feature.properties.id !== periodIdRef.current) {
          const data: PeriodWithIdType = feature.properties
          handleOnClickTimeLine(data)
        } else {
          setPeriodId(undefined)
        }
      },
      mouseover: () => {
        layer.bindPopup(popupLayer(feature), { minWidth: 180 }).openPopup()
      },
    })
  }

  useEffect(() => {
    if (coveragesPeriod.data) {
      const data = coveragesPeriod.data

      const periods = data.periods.map((period) => ({
        ...period,
        id: uuidv4(),
      }))

      setData({
        ...data,
        periods,
      })
      if (periods.length > 0) {
        setGeoJsonData(convertToGeoJson(periods))
      }
    }
  }, [coveragesPeriod.data])

  useEffect(() => {
    periodIdRef.current = periodId

    // when periodId is selected, filter the data
    if (data?.periods && data.periods.length > 0) {
      if (periodId) {
        setGeoJsonData(convertToGeoJson(data.periods.filter((item) => item.id === periodId)))
      } else {
        setGeoJsonData(convertToGeoJson(data.periods))
      }
    }
  }, [periodId, data?.periods])

  return {
    backToPolicyDetail,
    data,
    periodId,
    tviStatus,
    handleOnClickTimeLine,
    handleOnClickModeSummarize,
    handleOnViewLocation,
    onEachFeature,
    geoJsonData,
    setPeriodId,
  }
}

export default HistoryCarUsageDetailHook
