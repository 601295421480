import HistoryCarUsageMap from '../HistoryCarUsageMap/HistoryCarUsageMap'
import HistoryCarUsageDetailHook from './HistoryCarUsageDetail.hook'
import {
  BackIcon,
  Container,
  DateTimeText,
  SubContainer,
  Title,
  TitleContainer,
  Skeleton,
} from './styles'
import HistoryCarUsagePeriodTable from '@/components/feature/PolicyDetailPage/HistoryCarUsagePeriodTable/HistoryCarUsagePeriodTable'
import ModeSummarize from '../ModeSummarize'
import TimelineChart from '../TimelineChart'
import { formatThaiLocaleDateWithTimeMinute } from '@/libs/date'

function HistoryCarUsageDetail() {
  const {
    backToPolicyDetail,
    data,
    periodId,
    tviStatus,
    handleOnClickTimeLine,
    handleOnClickModeSummarize,
    handleOnViewLocation,
    onEachFeature,
    geoJsonData,
    setPeriodId,
  } = HistoryCarUsageDetailHook()

  return (
    data && (
      <Container>
        {!data ? (
          <Skeleton active />
        ) : (
          <>
            <div>
              <TitleContainer>
                <BackIcon onClick={backToPolicyDetail} />
                <Title>รายละเอียดการใช้งานรถ</Title>
              </TitleContainer>
              <DateTimeText>
                เมื่อวันที่ {formatThaiLocaleDateWithTimeMinute(data.startedAt)} -{' '}
                {formatThaiLocaleDateWithTimeMinute(data.stoppedAt)}
              </DateTimeText>
            </div>
            <SubContainer>
              <HistoryCarUsageMap
                geoJsonData={geoJsonData}
                onEachFeature={onEachFeature}
                setPeriodId={setPeriodId}
              />
              <TimelineChart
                data={data?.periods || []}
                periodId={periodId}
                tripStatus={tviStatus}
                handleOnClick={handleOnClickTimeLine}
              />
              <ModeSummarize
                data={data?.periods || []}
                tripStatus={tviStatus}
                handleOnClick={handleOnClickModeSummarize}
              />
              <HistoryCarUsagePeriodTable
                data={data?.periods || []}
                periodId={periodId}
                tripStatus={tviStatus}
                onRowClick={handleOnViewLocation}
              />
            </SubContainer>
          </>
        )}
      </Container>
    )
  )
}

export default HistoryCarUsageDetail
