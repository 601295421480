import { useState } from 'react'
import { DatePickerProps, TablePaginationConfig, TimePickerProps } from 'antd'
import { useRecoilValue } from 'recoil'
import { Dayjs } from 'dayjs'

import { useCoveragesPagination } from '@/services/returnTime/useCoveragesPagination'
import { HISTORY_CAR_USAGE_PAGINATION_LIMIT } from '@/constant/RETURN_TIME'
import policyAtom from '@/recoil/policy'

const HistoryCarUsageHook = () => {
  const policy = useRecoilValue(policyAtom)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentPageSize, setCurrentPageSize] = useState<number>(10)
  const [startDate, setStartDate] = useState<Dayjs | null>()
  const [startTime, setStartTime] = useState<Dayjs | null>()
  const [stopDate, setStopDate] = useState<Dayjs | null>()
  const [stopTime, setStopTime] = useState<Dayjs | null>()
  const isClearButton = startDate || startTime || stopDate || stopTime

  const isFieldComplete = startDate && stopDate && startTime && stopTime
  const fetchCoveragesPagination = useCoveragesPagination({
    query: {
      policyNumber: policy.policyNumber ? policy.policyNumber : undefined,
      startedAt: isFieldComplete
        ? startDate.hour(startTime.hour()).minute(startTime.minute()).format('YYYY-MM-DD HH:mm')
        : undefined,
      stoppedAt: isFieldComplete
        ? stopDate.hour(stopTime.hour()).minute(stopTime.minute()).format('YYYY-MM-DD HH:mm')
        : undefined,
    },
    pagination: {
      page: currentPage,
      limit: currentPageSize,
    },
  })

  const handleOnClearButton = () => {
    setStartDate(null)
    setStartTime(null)
    setStopDate(null)
    setStopTime(null)
  }

  const handleTimeChange = (
    startDate: Dayjs | null | undefined,
    stopDate: Dayjs | null | undefined,
    field: 'start' | 'stop',
  ) => {
    if (startDate?.isSame(stopDate) && startTime?.isAfter(stopTime)) {
      field === 'start' ? setStartTime(null) : setStopTime(null)
    }
  }

  const handleOnChangeStartDate: DatePickerProps['onChange'] = (date) => {
    const dayStart = date?.startOf('day')
    setStartDate(dayStart)
    handleTimeChange(dayStart, stopDate, 'start')
  }

  const handleDisabledStartDate: DatePickerProps['disabledDate'] = (current) => {
    if (current && stopDate) {
      const daysDiff = current.startOf('d').diff(stopDate.startOf('d'), 'd')
      return daysDiff > 0 || daysDiff <= -HISTORY_CAR_USAGE_PAGINATION_LIMIT
    }
    return false
  }

  const handleOnChangeStartTime: TimePickerProps['onChange'] = (date) => {
    setStartTime(date)
  }

  const handleDisabledStartTime: TimePickerProps['disabledTime'] = () => {
    if (!startDate?.isSame(stopDate) || !stopTime) {
      return {}
    }

    const stopHour = stopTime.hour()
    const stopMinute = stopTime.minute()

    return {
      disabledHours: () => {
        const hours = Array.from({ length: 24 }, (_, i) => i)
        return hours.filter((hour) => hour > stopHour) // Disable hours after the stopHour
      },
      disabledMinutes: (selectedHour) => {
        if (selectedHour === stopHour) {
          const minutes = Array.from({ length: 60 }, (_, i) => i)
          return minutes.filter((minute) => minute > stopMinute) // Disable minutes after the stopMinute for the selected stopHour
        }
        return []
      },
    }
  }

  const handleOnChangeStopDate: DatePickerProps['onChange'] = (date) => {
    const dayStop = date?.startOf('day')
    setStopDate(dayStop)
    handleTimeChange(startDate, dayStop, 'stop')
  }

  const handleDisabledStopDate: DatePickerProps['disabledDate'] = (current) => {
    if (current && startDate) {
      const daysDiff = current.startOf('d').diff(startDate.startOf('d'), 'd')
      return daysDiff < 0 || daysDiff >= HISTORY_CAR_USAGE_PAGINATION_LIMIT
    }
    return false
  }

  const handleOnChangeStopTime: TimePickerProps['onChange'] = (date) => {
    setStopTime(date)
  }

  const handleDisabledStopTime: TimePickerProps['disabledTime'] = () => {
    if (!startDate?.isSame(stopDate) || !startTime) {
      return {}
    }

    const startHour = startTime.hour()
    const startMinute = startTime.minute()

    return {
      disabledHours: () => {
        const hours = Array.from({ length: 24 }, (_, i) => i)
        return hours.filter((hour) => hour < startHour) // Disable hours before startHour
      },
      disabledMinutes: (selectedHour) => {
        if (selectedHour === startHour) {
          const minutes = Array.from({ length: 60 }, (_, i) => i)
          return minutes.filter((minute) => minute < startMinute) // Disable minutes before startMinute in the startHour
        }
        return []
      },
    }
  }

  const handleOnChangeTable = (pagination: TablePaginationConfig) => {
    // set pagination
    const { current, pageSize } = pagination
    if (current && pageSize) {
      setCurrentPage(current)
      setCurrentPageSize(pageSize)
    }
  }

  return {
    startDate,
    startTime,
    stopDate,
    stopTime,
    isClearButton,
    fetchCoveragesPagination,
    currentPage,
    currentPageSize,
    handleOnClearButton,
    handleDisabledStartDate,
    handleDisabledStopDate,
    handleDisabledStartTime,
    handleDisabledStopTime,
    handleOnChangeStartDate,
    handleOnChangeStopDate,
    handleOnChangeStartTime,
    handleOnChangeStopTime,
    handleOnChangeTable,
  }
}

export default HistoryCarUsageHook
