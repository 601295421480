import { UserSelectStyle } from '@/components/common/Table/styles'
import { formatLocaleDate } from '@/libs/utils/format-locale-date.util'
import { ColumnsType } from 'antd/lib/table'
import { ButtonIcon } from './styles'
import { IMAGE_URL } from '@/constant/IMAGE'
import { TVI_MODE } from '@/constant/RETURN_TIME'
import { useContext } from 'react'
import { PolicyDetailContext } from '@/contexts'

const HistoryCarUsageTableHook = ({
  currentPage = 1,
  currentPageSize = 10,
}: {
  currentPage?: number
  currentPageSize?: number
}) => {
  const { setSelectCoverageId } = useContext(PolicyDetailContext)
  const columns: ColumnsType<object> = [
    {
      width: 75,
      title: 'ลำดับ',
      align: 'center',
      render: (_value, _record, index) => (currentPage - 1) * currentPageSize + index + 1,
    },
    {
      title: 'วันที่เริ่ม',
      dataIndex: ['startedAt'],
      className: UserSelectStyle,
      render: (startedAt: Date) => {
        return startedAt ? formatLocaleDate(startedAt, 'th', 'D MMM BBBB') : '-'
      },
    },
    {
      title: 'เวลาเริ่ม',
      dataIndex: ['startedAt'],
      className: UserSelectStyle,
      render: (startedAt: Date) => {
        return startedAt ? formatLocaleDate(startedAt, 'th', 'HH:mm') : '-'
      },
    },
    {
      title: 'วันที่สิ้นสุด',
      dataIndex: ['stoppedAt'],
      className: UserSelectStyle,
      render: (stoppedAt: Date) => {
        return stoppedAt ? formatLocaleDate(stoppedAt, 'th', 'D MMM BBBB') : '-'
      },
    },
    {
      title: 'เวลาสิ้นสุด',
      dataIndex: ['stoppedAt'],
      className: UserSelectStyle,
      render: (stoppedAt: Date) => {
        return stoppedAt ? formatLocaleDate(stoppedAt, 'th', 'HH:mm') : '-'
      },
    },
    {
      title: 'เปิดด้วยโหมด',
      dataIndex: ['startedMode'],
      className: UserSelectStyle,
      render: (startedMode: keyof typeof TVI_MODE) => {
        return startedMode ? TVI_MODE[startedMode] : '-'
      },
    },
    {
      title: 'ปิดด้วยโหมด',
      dataIndex: ['stoppedMode'],
      className: UserSelectStyle,
      render: (stoppedMode: keyof typeof TVI_MODE) => {
        return stoppedMode ? TVI_MODE[stoppedMode] : '-'
      },
    },
    {
      width: 75,
      dataIndex: ['id'],
      render: (id) => {
        return <ButtonIcon src={IMAGE_URL.eyeIcon} onClick={() => setSelectCoverageId(id)} />
      },
    },
  ]

  return { columns, currentPage, currentPageSize }
}

export default HistoryCarUsageTableHook
