import { httpClientPrivate } from '@/libs/httpClient'
import config from '@/config'
import { useQuery } from '@tanstack/react-query'
import { CoveragesPeriodResponseType } from './types'

const fetchCoveragesPeriod = async (coverageId: string) => {
  const response = await httpClientPrivate.get<CoveragesPeriodResponseType>(
    `${config.restUrl}/v1/backoffice-ppu-motor/coverages/${coverageId}/period`,
  )

  return response.data
}

const useCoveragesPeriod = (query: { coverageId: string }) => {
  const queryKey = ['useCoveragesPeriod', query]
  const queryFn = async () => fetchCoveragesPeriod(query.coverageId)
  const queryResult = useQuery({ queryKey, queryFn })

  return { ...queryResult }
}

export { fetchCoveragesPeriod, useCoveragesPeriod }
