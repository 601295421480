import styled from '@emotion/styled'
import { Breadcrumb as AntBreadcrumb } from 'antd'
import CommonButton from '@/components/common/Button'

export const Container = styled('div')`
  padding: 40px;
`

export const HeaderWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const ButtonWrapper = styled('div')`
  display: flex;
  gap: 12px;
  justify-content: end;
  align-items: center;
`

export const TitleWrapper = styled('div')`
  display: grid;
`

export const ButtonDefault = styled(CommonButton)`
  line-height: 20px;
  padding: 10px 16px;
`

export const Title = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.004em;
  color: #000000;
`

export const Detail = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.004em;
  color: #666666;
`

export const IconSvg = styled('img')`
  width: 20px;
  height: 20px;
`

export const Breadcrumb = styled(AntBreadcrumb)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.004em;
  margin-bottom: 24px;
`

export const DescriptionWrapper = styled('div')`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 24px;

  img {
    width: 100%;
  }
`
