import { DatePicker as AntdDatePicker, TimePicker as AntdTimePicker } from 'antd'
import styled from '@emotion/styled'

export const Container = styled('div')`
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 24px 32px;
  width: 100%;
  position: relative;
  user-select: none;
`

export const ContainerInput = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 530px;
`

export const ContainerInputDate = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

export const ClearInputAll = styled('div')`
  padding-left: 10px;
  color: #009640;
  cursor: pointer;
`

export const DatePicker = styled(AntdDatePicker)`
  width: 150px;
`

export const TimePicker = styled(AntdTimePicker)`
  width: 100px;
`

export const Dash = styled('div')`
  color: #adadad;
`
