import dayjs from 'dayjs'
import { Form } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { RangePickerProps } from 'antd/es/date-picker'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

import { CreateActiveHealthPartnerBranchInput } from '@/gql/activeHealthPartnerBranch/backofficeCreateActiveHealthPartnerBranch/interface'
import { activeHealthPartnerBranchGql, activeHealthPartnerGql } from '@/gql'
import { SubDistrictType } from '@/services/address/useSubDistrict/types'
import { useSubDistrict } from '@/services/address/useSubDistrict'
import { useDistrict } from '@/services/address/useDistrict'
import { useProvince } from '@/services/address/useProvince'
import message from '@/libs/message'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

type SelectOption = {
  label: string
  value: string
}

const WellBeingBranchModalHook = ({
  partnerId,
  isModalOpen,
  handleOk,
  handleCancel,
}: {
  partnerId: string
  isModalOpen: boolean
  handleOk?: () => void
  handleCancel?: () => void
}) => {
  const [form] = Form.useForm()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isDisableDistrict, setDisableDistrict] = useState<boolean>(true)
  const [isDisableSubDistrict, setDisableSubDistrict] = useState<boolean>(true)
  const [isDisablePostCode, setDisablePostCode] = useState<boolean>(true)
  const [postCodeOptions, setPostCodeOptions] = useState<SelectOption[] | undefined>([])
  const [provinceCode, setProvinceCode] = useState<string | undefined>()
  const [districtCode, setDistrictCode] = useState<string | undefined>()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const [fetchBonusCriteria, { data: bonusCriteriaData, loading: isBonusCriteriaLoading }] =
    useLazyQuery(activeHealthPartnerGql.query.backofficeGetRecentActiveHealthBonusCriteria, {
      fetchPolicy: 'no-cache',
    })
  const [mutationPartnerBranch, { loading: isMutationLoading }] = useMutation(
    activeHealthPartnerBranchGql.mutation.backofficeCreateActiveHealthPartnerBranch,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const useAddress = (provinceCode?: string, districtCode?: string) => {
    const provinces = useProvince().data
    const districts = useDistrict(provinceCode || '').data
    const subDistricts = useSubDistrict(districtCode || '').data
    const provinceOptions = provinces?.map((province) => ({
      label: province.displayNameTh,
      value: province.provinceCode,
    }))
    const districtOptions = districts?.map((district) => ({
      label: district.displayNameTh,
      value: district.districtCode,
    }))
    const subDistrictOptions = subDistricts?.map((subDistrict) => ({
      label: subDistrict.displayNameTh,
      value: subDistrict.subDistrictCode,
    }))

    return {
      provinces,
      districts,
      subDistricts,
      provinceOptions,
      districtOptions,
      subDistrictOptions,
    }
  }

  const { subDistricts, districtOptions, provinceOptions, subDistrictOptions } = useAddress(
    provinceCode,
    districtCode,
  )

  const resetFieldsOnProvinceChange = (provinceCode: string | undefined) => {
    if (provinceCode) {
      form.setFieldsValue({
        districtCode: undefined,
        subDistrictCode: undefined,
        postCode: undefined,
      })
      setProvinceCode(provinceCode)
      setDistrictCode(undefined)
      setDisableDistrict(false)
      setDisableSubDistrict(true)
      setDisablePostCode(true)
    }
  }

  const resetFieldsOnDistrictChange = (districtCode: string | undefined) => {
    if (districtCode) {
      form.setFieldsValue({
        subDistrictCode: undefined,
        postCode: undefined,
      })
      setDistrictCode(districtCode)
      setDisableSubDistrict(false)
      setDisablePostCode(true)
    }
  }

  const handleSubDistrictChange = (subDistrictCode: string | undefined) => {
    if (subDistrictCode) {
      const postCodeOptions = getPostCodeOptions(subDistrictCode)
      form.setFieldsValue({
        postCode: postCodeOptions?.[0]?.value,
      })
      setPostCodeOptions(postCodeOptions)
      setDisablePostCode(false)
    }
  }

  const getPostCodeOptions = (subDistrictCode: string) => {
    return subDistricts
      ?.filter((subDistrict: SubDistrictType) => subDistrict.subDistrictCode === subDistrictCode)
      .map((data) => ({
        label: data.postCode,
        value: data.postCode,
      }))
  }

  const handleFormChange = (allValues: any) => {
    const { provinceCode, districtCode, subDistrictCode } = allValues

    resetFieldsOnProvinceChange(provinceCode)
    resetFieldsOnDistrictChange(districtCode)
    handleSubDistrictChange(subDistrictCode)
  }

  const onSubmit = (): void => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true)
        if (values) {
          const {
            displayNameTh,
            displayNameEn,
            addressTh,
            addressEn,
            subDistrictCode,
            lat,
            long,
            contacts,
            dateRang,
            isActiveQr,
          } = values

          const input: CreateActiveHealthPartnerBranchInput = {
            partnerId,
            displayNameTh,
            displayNameEn,
            addressTh,
            addressEn,
            lat: Number(lat),
            long: Number(long),
            subDistrictCode,
            contacts,
            startDate: dayjs(dateRang[0]).startOf('day').format('YYYY-MM-DD'),
            endDate: dayjs(dateRang[1]).startOf('day').format('YYYY-MM-DD'),
            isActiveQr,
          }

          mutationPartnerBranch({
            variables: {
              input,
            },
          })
            .then(() => {
              if (handleOk) {
                handleOk()
              }
              message.success({
                content: 'เพิ่มสาขาสำเร็จ',
                duration: 10,
              })
              onClose()
            })
            .catch((error) => {
              message.error(error?.message || 'กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง')
            })
        }
      })
      .catch(() => {
        message.error({
          content: 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onClose = (): void => {
    if (handleCancel) {
      handleCancel()
    }
    form.resetFields()
  }

  const disabledDatePickerRangePicker: RangePickerProps['disabledDate'] = (current) => {
    if (!bonusCriteriaData?.backofficeGetRecentActiveHealthBonusCriteria) return true

    const startCampaign = dayjs(
      bonusCriteriaData?.backofficeGetRecentActiveHealthBonusCriteria.startDate,
    )
    const endCampaign = dayjs(
      bonusCriteriaData?.backofficeGetRecentActiveHealthBonusCriteria.endDate,
    )

    return (
      current < dayjs().startOf('day') ||
      current.isBefore(startCampaign, 'day') ||
      current.isAfter(endCampaign, 'day')
    )
  }

  const validateLatitude = (_: any, data: string) => {
    const value = Number(data)

    if (!isNaN(value) && value >= -90 && value <= 90) {
      return Promise.resolve()
    }

    return Promise.reject(new Error('ละติจูดต้องเป็นตัวเลขระหว่าง -90 ถึง 90'))
  }

  const validateLongitude = (_: any, data: string) => {
    const value = Number(data)

    if (!isNaN(value) && value >= -180 && value <= 180) {
      return Promise.resolve()
    }

    return Promise.reject(new Error('ลองจิจูดต้องเป็นตัวเลขระหว่าง -180 ถึง 180'))
  }

  const handleFormComplete = () => {
    const values = form.getFieldsValue()
    const isEmpty = Object.values(values || []).some((item: any) => item == null)
    setIsButtonDisabled(isEmpty)
  }

  const getRecentBonusCriteria = useCallback(() => {
    fetchBonusCriteria()
  }, [fetchBonusCriteria])

  useEffect(() => {
    getRecentBonusCriteria()
  }, [])

  useEffect(() => {
    const { startDate, endDate } =
      bonusCriteriaData?.backofficeGetRecentActiveHealthBonusCriteria || {}
    if (!bonusCriteriaData || form.getFieldValue('dateRang') || !startDate || !endDate) return

    const startOfDay = dayjs().startOf('day')
    const startCampaign = dayjs(startDate).isAfter(startOfDay) ? dayjs(startDate) : startOfDay
    const endCampaign = dayjs(endDate)

    form.setFieldValue('dateRang', [startCampaign, endCampaign])
  }, [bonusCriteriaData, form, isModalOpen])

  return {
    form,
    isLoading,
    isBonusCriteriaLoading,
    isMutationLoading,
    isDisableDistrict,
    isDisableSubDistrict,
    isDisablePostCode,
    isButtonDisabled,
    districtOptions,
    provinceOptions,
    subDistrictOptions,
    postCodeOptions,
    disabledDatePickerRangePicker,
    validateLatitude,
    validateLongitude,
    handleFormChange,
    handleFormComplete,
    onSubmit,
    onClose,
  }
}

export default WellBeingBranchModalHook
