import { useCallback, useEffect, useState } from 'react'
import { Badge } from 'antd'
import dayjs from 'dayjs'
import { ColumnsType } from 'antd/lib/table'
import { debounceTime, distinctUntilChanged, fromEvent, map, of, switchMap, tap } from 'rxjs'
import { useRecoilValue } from 'recoil'
import { useLazyQuery } from '@apollo/client'
import { ButtonIcon } from '@/components/feature/WellBeingPage/WellBeingPartnerTable/styles'
import { IMAGE_URL } from '@/constant/IMAGE'
import activeHealthPartner from '@/gql/activeHealthPartner'
import {
  ActiveHealthPartnerInputInterface,
  ActiveHealthPartnerInterface,
} from '@/gql/activeHealthPartner/backofficeGetActiveHealthPartnerPagination/interfaces'
import { PartnerColumnContainer, PartnerImage } from './styles'
import { generatePath, useNavigate } from 'react-router-dom'
import CONSTANT from '@/constant'
import userAtom from '@/recoil/user/atom'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { SortByEnum } from '@/constant/SORT_BY'
import { SorterResult } from 'antd/lib/table/interface'
import { SORTER, SorterEnum } from '@/constant/USER'

function WellBeingPartnerTableHook() {
  const user = useRecoilValue(userAtom)
  const navigate = useNavigate()
  const searchElement = document.getElementById('well-being-search')
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [sort, setSort] = useState<[string, SortByEnum | '']>(['createdAt', SortByEnum.DESC])
  const [pagination, setPagination] = useState<{
    page: number
    limit: number
    totalItems?: number
  }>({
    page: 1,
    limit: 10,
    totalItems: 0,
  })
  const [
    getActiveHealthPartnerPagination,
    { data: activeHealthPartnerData, loading: activeHealthPartnerLoading },
  ] = useLazyQuery(activeHealthPartner.query.backofficeGetActiveHealthPartnerPagination, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.backofficeGetActiveHealthPartnerPagination.meta) {
        setPagination({
          page: data.backofficeGetActiveHealthPartnerPagination.meta.currentPage,
          limit: data.backofficeGetActiveHealthPartnerPagination.meta.itemsPerPage,
          totalItems: data.backofficeGetActiveHealthPartnerPagination.meta.totalItems,
        })
      }
    },
  })
  const columns: ColumnsType<ActiveHealthPartnerInterface | {}> = [
    {
      title: 'ลำดับ',
      align: 'center',
      render: (_, __, index) => (pagination.page - 1) * pagination.limit + index + 1,
    },
    {
      title: 'ชื่อพาร์ทเนอร์',
      key: 'displayNameEn',
      sorter: true,
      sortDirections: [SorterEnum.DESC, SorterEnum.ASC],
      defaultSortOrder: null,
      render: (_, record) => {
        const data = record as ActiveHealthPartnerInterface

        return (
          <PartnerColumnContainer>
            <span>
              <PartnerImage src={data.logoImageUrl} />
            </span>
            <span>
              <div>{data.displayNameEn}</div>
              <div style={{ color: '#666666' }}>{data.displayNameTh}</div>
            </span>
          </PartnerColumnContainer>
        )
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      sortDirections: [SorterEnum.DESC, SorterEnum.ASC],
      defaultSortOrder: null,
      render: (isActive: boolean) => {
        return (
          <div>
            <Badge status={isActive ? 'success' : 'error'} />{' '}
            {isActive ? 'เปิดการใช้งาน' : 'ปิดการใช้งาน'}
          </div>
        )
      },
    },
    {
      title: 'จำนวนสาขา',
      render: (_, record) => {
        const data = record as ActiveHealthPartnerInterface

        return data?.activeHealthPartnerBranch?.length || '-'
      },
    },
    {
      title: 'หมวดหมู่',
      dataIndex: ['activeHealthPartnerCategory', 'displayNameEn'],
    },
    {
      title: 'สร้างโดย',
      dataIndex: 'createdByText',
    },
    {
      title: 'แก้ไขล่าสุด',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      sortDirections: [SorterEnum.DESC, SorterEnum.ASC],
      defaultSortOrder: null,
      render: (updatedAt: Date) => {
        return dayjs(updatedAt).format('DD-MM-YYYY')
      },
    },
    {
      fixed: 'right',
      dataIndex: 'id',
      render: (id: string) => {
        return (
          <div>
            {validatePerm([BACKOFFICE_USER_PERMISSION.WBE010103], user.permissions) ? (
              <ButtonIcon src={IMAGE_URL.eyeIcon} onClick={() => onSelectPartner(id)} />
            ) : undefined}
          </div>
        )
      },
    },
  ]

  const onSelectPartner = (id: string) => {
    navigate(
      generatePath(CONSTANT.ROUTES.WELL_BEING_PARTNER_ID_PAGE, {
        partnerId: id,
      }),
      {
        state: {
          referrer: CONSTANT.ROUTES.WELL_BEING_PAGE,
        },
      },
    )
  }
  const getActiveHealthPartner = useCallback(
    (input?: ActiveHealthPartnerInputInterface) => {
      getActiveHealthPartnerPagination({
        variables: {
          input: {
            search: input?.search || '',
            searchBy: ['displayNameTh', 'displayNameEn'],
            page: pagination.page || 1,
            limit: pagination.limit || 10,
            sortBy: input?.sortBy,
          },
        },
      })
    },
    [getActiveHealthPartnerPagination, pagination.limit, pagination.page],
  )

  const onTablePaginate = (page: number, pageSize: number) => {
    setPagination({
      page,
      limit: pageSize,
    })
  }

  const onTableChange = (sorter: SorterResult<{}> | Array<SorterResult<{}>>) => {
    if (sorter) {
      const sort = sorter as SorterResult<{}>
      const sortBy = sort.columnKey as string
      const sortOrder = sort.order as SorterEnum

      setSort([sortBy, SORTER[sortOrder] || ''])
    }
  }

  const onSearchInputChange = (isTyping: boolean) => setIsTyping(isTyping)

  const handleSetSearch = (input: string) =>
    of(setSearch(input)).pipe(
      tap(() => {
        setPagination({
          page: 1,
          limit: 10,
          totalItems: pagination.totalItems,
        })
      }),
    )

  useEffect(() => {
    getActiveHealthPartner({
      search,
      limit: pagination.limit,
      page: pagination.page,
      sortBy: sort[0] ? [sort] : undefined,
    })
  }, [search, pagination.page, pagination.limit, sort])

  useEffect(() => {
    if (isTyping && searchElement) {
      fromEvent(searchElement, 'keyup')
        .pipe(
          debounceTime(1000),
          map((e: Event) => {
            const target = e.target as HTMLInputElement

            return target.value || ''
          }),
          distinctUntilChanged(),
          switchMap(handleSetSearch),
          tap(() => setIsTyping(false)),
        )
        .subscribe()
    }
  }, [isTyping, searchElement])

  return {
    columns,
    activeHealthPartnerData,
    activeHealthPartnerLoading,
    onTablePaginate,
    onSearchInputChange,
    pagination,
    onTableChange,
  }
}

export default WellBeingPartnerTableHook
