import locale from 'antd/es/date-picker/locale/th_TH'

export const datePickerTh = {
  ...locale,
  lang: {
    ...locale.lang,
    yearFormat: 'BBBB',
    cellYearFormat: 'BBBB',
    dateFormat: 'M/D/BBBB',
    dateTimeFormat: 'M/D/BBBB HH:mm:ss',
  },
  dateFormat: 'BBBB-MM-DD',
  dateTimeFormat: 'BBBB-MM-DD HH:mm:ss',
  weekFormat: 'BBBB-wo',
  monthFormat: 'BBBB-MM',
}
