import {
  ClearInputAll,
  Container,
  ContainerInput,
  ContainerInputDate,
  Dash,
  DatePicker,
  TimePicker,
} from './styles'
import { datePickerTh } from '@/components/common/BuddhistDatePicker/config'
import HistoryCarUsageTable from '../HistoryCarUsageTable'
import HistoryCarUsageHook from './HistoryCarUsage.hook'

const HistoryCarUsage = () => {
  const {
    startDate,
    startTime,
    stopDate,
    stopTime,
    isClearButton,
    fetchCoveragesPagination,
    currentPage,
    currentPageSize,
    handleOnClearButton,
    handleDisabledStartDate,
    handleDisabledStopDate,
    handleDisabledStartTime,
    handleDisabledStopTime,
    handleOnChangeStartDate,
    handleOnChangeStopDate,
    handleOnChangeStartTime,
    handleOnChangeStopTime,
    handleOnChangeTable,
  } = HistoryCarUsageHook()

  return (
    <Container>
      <ContainerInputDate>
        <ContainerInput>
          <DatePicker
            placeholder="วันที่เริ่ม"
            locale={datePickerTh}
            format="DD/MM/BBBB"
            showToday={false}
            onChange={handleOnChangeStartDate}
            disabledDate={handleDisabledStartDate}
            value={startDate}
          />
          <TimePicker
            placeholder="00:00"
            format="HH:mm"
            use12Hours={false}
            showSecond={false}
            showNow={false}
            onChange={handleOnChangeStartTime}
            disabledTime={handleDisabledStartTime}
            value={startTime}
          />
          <Dash>-</Dash>
          <DatePicker
            placeholder="วันที่สิ้นสุด"
            locale={datePickerTh}
            format="DD/MM/BBBB"
            showToday={false}
            onChange={handleOnChangeStopDate}
            disabledDate={handleDisabledStopDate}
            value={stopDate}
          />
          <TimePicker
            placeholder="00:00"
            format="HH:mm"
            use12Hours={false}
            showSecond={false}
            showNow={false}
            onChange={handleOnChangeStopTime}
            disabledTime={handleDisabledStopTime}
            value={stopTime}
          />
        </ContainerInput>
        {isClearButton && <ClearInputAll onClick={handleOnClearButton}>ล้างข้อมูล</ClearInputAll>}
      </ContainerInputDate>
      <HistoryCarUsageTable
        isLoading={fetchCoveragesPagination.isFetching}
        currentPage={currentPage}
        currentPageSize={currentPageSize}
        pagination={fetchCoveragesPagination.data}
        handleOnChangeTable={handleOnChangeTable}
      />
    </Container>
  )
}

export default HistoryCarUsage
