import Modal from '@/components/common/Modal'
import { IMAGE_URL } from '@/constant/IMAGE'
import {
  AddContact,
  BoxContact,
  BoxTitle,
  BuddhistDatePickerStyled,
  ColAntd,
  Container,
  Form,
  FormItem,
  Input,
  Line,
  Mark,
  RadioGroup,
  Radio,
  RowAntd,
  Select,
  Title,
  RadioTitle,
  RadioSubTitle,
  MarkOpen,
  MarkClose,
  DeleteIcon,
  LabelOptional,
  Button,
  FormList,
  BoxNoContact,
  TitleNoContact,
  SubTitleNoContact,
} from './styles'
import WellBeingBranchModalHook from './WellBeingBranchModal.hook'

const WellBeingBranchModal = ({
  partnerId,
  isModalOpen,
  title,
  handleOk,
  handleCancel,
}: {
  partnerId: string
  isModalOpen: boolean
  title: string
  handleOk?: () => void
  handleCancel?: () => void
}) => {
  const {
    form,
    isLoading,
    isBonusCriteriaLoading,
    isMutationLoading,
    isDisableDistrict,
    isDisableSubDistrict,
    isDisablePostCode,
    isButtonDisabled,
    districtOptions,
    provinceOptions,
    subDistrictOptions,
    postCodeOptions,
    disabledDatePickerRangePicker,
    validateLatitude,
    validateLongitude,
    handleFormChange,
    handleFormComplete,
    onSubmit,
    onClose,
  } = WellBeingBranchModalHook({ partnerId, isModalOpen, handleOk, handleCancel })

  const filterOption = (input: string, option: any) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  return (
    <Modal
      title={title}
      open={isModalOpen}
      width={900}
      data-testid="well-being-branch-modal"
      centered
      forceRender
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          data-testid="well-being-branch-modal-form-button-cancel"
          onClick={onClose}
        >
          ยกเลิก
        </Button>,
        <Button
          key="submit"
          type="primary"
          data-testid="well-being-branch-modal-form-button-confirm"
          loading={isLoading || isBonusCriteriaLoading || isMutationLoading}
          disabled={isButtonDisabled}
          onClick={onSubmit}
        >
          บันทึก
        </Button>,
      ]}
    >
      <Container>
        <Form
          layout="vertical"
          autoComplete="off"
          data-testid="well-being-branch-modal-form"
          requiredMark={false}
          form={form}
          onValuesChange={handleFormChange}
          onFieldsChange={handleFormComplete}
          initialValues={{
            contacts: [],
            isActiveQr: false,
          }}
        >
          <RowAntd gutter={[8, 0]}>
            <ColAntd span={12}>
              <FormItem
                label="ชื่อสาขา (ไทย)*"
                name="displayNameTh"
                rules={[{ required: true }]}
                normalize={(value) => (value === '' ? undefined : value)}
              >
                <Input
                  placeholder="ระบุชื่อสาขาเป็นภาษาไทย"
                  data-testid="well-being-branch-modal-input"
                />
              </FormItem>
            </ColAntd>
            <ColAntd span={12}>
              <FormItem
                label="ชื่อสาขา (Eng)*"
                name="displayNameEn"
                rules={[{ required: true }]}
                normalize={(value) => (value === '' ? undefined : value)}
              >
                <Input
                  placeholder="ระบุชื่อสาขาเป็นภาษาอังกฤษ"
                  data-testid="well-being-branch-modal-input"
                />
              </FormItem>
            </ColAntd>
          </RowAntd>
          <BoxTitle>
            <Title>ข้อมูลที่อยู่</Title>
          </BoxTitle>
          <RowAntd gutter={[8, 0]}>
            <ColAntd span={12}>
              <FormItem
                label="ที่อยู่ (ไทย)*"
                name="addressTh"
                rules={[{ required: true }]}
                normalize={(value) => (value === '' ? undefined : value)}
              >
                <Input
                  placeholder="หมู่บ้าน,เลขที่,หมู่ที่,ถนน"
                  data-testid="well-being-branch-modal-input"
                />
              </FormItem>
            </ColAntd>
            <ColAntd span={12}>
              <FormItem
                label="ที่อยู่ (Eng)*"
                name="addressEn"
                rules={[{ required: true }]}
                normalize={(value) => (value === '' ? undefined : value)}
              >
                <Input
                  placeholder="หมู่บ้าน,เลขที่,หมู่ที่,ถนน"
                  data-testid="well-being-branch-modal-input"
                />
              </FormItem>
            </ColAntd>
            <ColAntd span={12}>
              <FormItem label="จังหวัด*" name="provinceCode" rules={[{ required: true }]}>
                <Select
                  showSearch
                  placeholder="เลือกจังหวัด"
                  options={provinceOptions}
                  filterOption={filterOption}
                />
              </FormItem>
            </ColAntd>
            <ColAntd span={12}>
              <FormItem label="เขต/อำเภอ*" name="districtCode" rules={[{ required: true }]}>
                <Select
                  showSearch
                  placeholder="เลือกเขต/อำเภอ"
                  disabled={isDisableDistrict}
                  options={districtOptions}
                  filterOption={filterOption}
                />
              </FormItem>
            </ColAntd>
            <ColAntd span={12}>
              <FormItem label="แขวง/ตำบล*" name="subDistrictCode" rules={[{ required: true }]}>
                <Select
                  showSearch
                  placeholder="เลือกแขวง/ตำบล"
                  disabled={isDisableSubDistrict}
                  options={subDistrictOptions}
                  filterOption={filterOption}
                />
              </FormItem>
            </ColAntd>
            <ColAntd span={12}>
              <FormItem label="รหัสไปรษณีย์*" name="postCode" rules={[{ required: true }]}>
                <Select
                  placeholder="เลือกรหัสไปรษณีย์"
                  options={postCodeOptions}
                  disabled={isDisablePostCode}
                />
              </FormItem>
            </ColAntd>
            <ColAntd span={12}>
              <FormItem
                label="Lat*"
                name="lat"
                rules={[{ validator: validateLatitude }]}
                normalize={(value) => (value === '' ? undefined : value)}
              >
                <Input
                  type="number"
                  placeholder="ระบุ"
                  data-testid="well-being-branch-modal-input"
                />
              </FormItem>
            </ColAntd>
            <ColAntd span={12}>
              <FormItem
                label="Long*"
                name="long"
                rules={[{ validator: validateLongitude }]}
                normalize={(value) => (value === '' ? undefined : value)}
              >
                <Input
                  type="number"
                  placeholder="ระบุ"
                  data-testid="well-being-branch-modal-input"
                />
              </FormItem>
            </ColAntd>
          </RowAntd>
          <FormList name="contacts">
            {(fields, { add, remove }) => (
              <>
                <BoxTitle>
                  <Title>
                    ข้อมูลติดต่อ <Mark>(เพิ่มได้สูงสุด 4 เบอร์)</Mark>
                  </Title>
                  <AddContact
                    onClick={() => {
                      if (fields.length < 4) {
                        add()
                      }
                    }}
                  >
                    เพิ่มข้อมูล
                  </AddContact>
                </BoxTitle>
                {fields.length > 0 ? (
                  <RowAntd gutter={[8, 16]}>
                    {fields.map((field) => (
                      <ColAntd span={24} key={field.key}>
                        <DeleteIcon
                          src={IMAGE_URL.deleteIcon}
                          onClick={() => {
                            remove(field.name)
                          }}
                        />
                        <BoxContact>
                          <RowAntd gutter={[8, 0]}>
                            <ColAntd span={12}>
                              <FormItem
                                label="เบอร์โทร"
                                name={[field.name, 'phoneNumber']}
                                rules={[{ required: true }]}
                                normalize={(value) => (value === '' ? undefined : value)}
                              >
                                <Input
                                  placeholder="ระบุ"
                                  data-testid="well-being-branch-modal-input"
                                />
                              </FormItem>
                            </ColAntd>
                            <ColAntd span={12}>
                              <FormItem
                                label={
                                  <>
                                    ต่อ<LabelOptional>(ถ้ามี)</LabelOptional>
                                  </>
                                }
                                name={[field.name, 'extensionNumber']}
                                normalize={(value) => (value === '' ? undefined : value)}
                              >
                                <Input
                                  placeholder="ระบุ"
                                  data-testid="well-being-branch-modal-input"
                                />
                              </FormItem>
                            </ColAntd>
                          </RowAntd>
                        </BoxContact>
                      </ColAntd>
                    ))}
                  </RowAntd>
                ) : (
                  <BoxNoContact>
                    <TitleNoContact>ไม่มีข้อมูล</TitleNoContact>
                    <SubTitleNoContact>คุณสามารถเพิ่มข้อมูลติดต่อในระบบนี้</SubTitleNoContact>
                    <SubTitleNoContact>เพียงกดปุ่ม “เพิ่มข้อมูล”</SubTitleNoContact>
                  </BoxNoContact>
                )}
              </>
            )}
          </FormList>
          <Line />
          <FormItem
            label="วันที่เริ่มต้น - วันที่สิ้นสุด*"
            name="dateRang"
            rules={[{ required: true }]}
          >
            <BuddhistDatePickerStyled
              format="DD/MM/BBBB"
              data-testid="well-being-branch-modal-datepicker"
              disabledDate={disabledDatePickerRangePicker}
            />
          </FormItem>
          <FormItem label="สถานะการใช้งาน QR Code*" name="isActiveQr">
            <RadioGroup>
              <RowAntd gutter={[8, 0]}>
                <ColAntd span={12}>
                  <Radio value={true}>
                    <RadioTitle>
                      <MarkOpen>เปิด</MarkOpen>การใช้งาน
                    </RadioTitle>
                    <RadioSubTitle>ลูกค้าสแกนรับคะแนนได้ ตามช่วงเวลาที่กำหนด</RadioSubTitle>
                  </Radio>
                </ColAntd>
                <ColAntd span={12}>
                  <Radio value={false}>
                    <RadioTitle>
                      <MarkClose>ปิด</MarkClose>การใช้งาน
                    </RadioTitle>
                    <RadioSubTitle>ลูกค้าไม่สามารถสแกนได้</RadioSubTitle>
                  </Radio>
                </ColAntd>
              </RowAntd>
            </RadioGroup>
          </FormItem>
        </Form>
      </Container>
    </Modal>
  )
}

export default WellBeingBranchModal
